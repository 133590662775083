<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h5 class="mb-4">
          <strong>Tambah Laboratorium Baru</strong>
        </h5>
        <a-form :form="form"  @submit="handleSubmit">
          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Nama Laboratorium">
            <a-input
              :disabled="submitting"
              placeholder="Masukkan Nama Laboratorium"
              v-decorator="['name', {rules: [{ required: true, message: 'Nama Laboratorium harus diisi!' }]}]"
            />
          </a-form-item>
          <!-- <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Alamat Laboratorium">
            <a-input
              :disabled="submitting"
              placeholder="Nomor KTP"
              v-decorator="['idNumber', { rules: [{required: true, len: 16, message: 'Nomor KTP harus 16 digit!' }]}]"
            />
          </a-form-item> -->
          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Alamat Laboratorium">
            <a-input
              :disabled="submitting"
              placeholder="Masukkan Alamat Laboratorium"
              v-decorator="['address', {rules: [{ required: true, message: 'Alamat laboratorium harus diisi!' }]}]"
            />
          </a-form-item>
          <!-- <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Rumah Sakit">
            <a-input
              :disabled="submitting"
              placeholder="Rumah Sakit"
              v-decorator="['hospital', {rules: [{ required: true, message: 'Nama rumah sakit harus diisi!' }]}]"
            />
          </a-form-item> -->
          <!-- <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Kota">
            <a-select
              :disabled="submitting"
              v-decorator="[
                'city',
                { rules: [{ required: true, message: 'Kota harus diisi!' }] },
              ]"
              placeholder="Kota"
            >
              <a-select-option v-for="city in cityList" :key="city.id" :value="city.name">
                  {{city.name}}
               </a-select-option>
            </a-select>
          </a-form-item> -->
          <button type="submit" class="btn btn-success px-5" :disabled="submitting">Simpan Data</button>
        </a-form>
      </div>
    </div>
  </div>
</template>
<script>

import router from '@/router'
import { create } from '@/services/axios/api/laboratorium'
// import { getAll } from '@/services/axios/api/city'
import { notification } from 'ant-design-vue'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 4 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 12 },
}
export default {
  data: function () {
    return {
      form: this.$form.createForm(this),
      labelCol,
      wrapperCol,
      cityList: [],
      submitting: false,
    }
  },
  // created() {
  //   this.getCityList()
  // },
  methods: {
    // async getCityList() {
    //   const res = await getAll()
    //   this.cityList = res
    // },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.submitting = true
          console.log('Received values of form: ', values)
          try {
            const res = await create(values)
            if (res && res.data) {
              notification.success({
                message: 'Sukses',
                description: 'Data berhasil ditambahkan',
                duration: 5,
              })
              router.push('/laboratorium')
            }
            this.submitting = false
          } catch (e) {
            this.submitting = false
          }
        }
      })
    },
  },
}
</script>
